import { handleResponse } from './misc';

function uploadMedia(authToken, fileObject){
    var data = new FormData()
    data.append('file', fileObject)

    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken },
        body: data
    };

    return fetch('/upload', requestOptions).then(handleResponse).then((res) => {
        return res;
    });
}

function isImage(type){
    const imageType = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/tiff'];
    return imageType.includes(type);
}

function isVideo(type){
    const videoTypes = ['video/mp4', 'video/x-msvideo', 'video/mpeg', 'video/ogg', 'video/mp2t'];
    return videoTypes.includes(type);
}

function isPDF(type){
    const videoTypes = ['application/pdf'];
    return videoTypes.includes(type);
}

export {
    uploadMedia,
    isImage,
    isVideo,
    isPDF
};