import React from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { knowledgeSetService } from '../../services/knowledgeSets';
import { connect } from 'react-redux';

class QuestionRephrasing extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            editingRephrasing: false,
            questionPhrasing: this.props.questionPhrasing,
            dbValue: this.props.questionPhrasing
        };

        this.handleEditRephrasingClick = this.handleEditRephrasingClick.bind(this);
        this.handleEditRephrasingBlur = this.handleEditRephrasingBlur.bind(this);
        this.handleEditRephrasingChange = this.handleEditRephrasingChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.questionPhrasing !== this.props.questionPhrasing) {
          this.setState({questionPhrasing: this.props.questionPhrasing});
        }
    }

    handleEditRephrasingClick(){
        this.setState({ editingRephrasing: true });
    }

    handleEditRephrasingChange(e){
        this.setState({ questionPhrasing: e.target.value });
    }

    handleEditRephrasingBlur(){
        this.setState({ editingRephrasing: false });
        const index = this.props.question.question.findIndex((question) => question === this.state.dbValue)
        const newRephrasings = this.props.question.question;
        newRephrasings[index] = this.state.questionPhrasing;
        knowledgeSetService.updateQuestion(this.props.question._id, { question: newRephrasings }, this.props.auth.agent.token).then(() => {
            this.setState((prevState) => {
                return { dbValue: prevState.questionPhrasing }
            });
        });
    }

    render(){
        return (
            <div className="question_item__questions_rephrasing">{this.state.editingRephrasing ? <input autoFocus type="text" onChange={this.handleEditRephrasingChange} onBlur={this.handleEditRephrasingBlur} value={this.state.questionPhrasing}/> : <div className="question_item__questions_rephrasing__text" onClick={this.handleEditRephrasingClick}>{this.state.questionPhrasing}</div>} {this.props.question.question.length > 1 && <div className="remove_rephrasing" onClick={() => { this.props.handleRemoveRephrasing(this.state.dbValue) }}><HiOutlineX/></div>}</div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(QuestionRephrasing);