const validatePassword = (password, password_confirm) => {
    let valid = {
        isValid: true,
        message: undefined
    };

    if(password.length === 0 && password_confirm.length === 0){
        valid.isValid = false;
        return valid;
    }

    //eslint-disable-next-line
    const regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if(!password.match(regx)){
        valid.isValid = false;
        valid.message = 'Passwords must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and be at least 8 characters long'
        return valid;
    }

    if(password !== password_confirm){
        valid.isValid = false;
        if(password_confirm.length >= 1){
            valid.message = 'Passwords must match'
        }
        return valid;
    }

    return valid;
}

export {
    validatePassword
};