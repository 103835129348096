import React from 'react';
import { connect } from 'react-redux';
import { HiPlusCircle, HiOutlineTrash, HiOutlineTag, HiOutlineSave, HiCheck } from 'react-icons/hi';
import { CgMoveTask } from 'react-icons/cg';
import { FiMove } from 'react-icons/fi';
import { knowledgeSetService } from '../../services/knowledgeSets';
import TextareaAutosize from 'react-textarea-autosize';
import QuestionAnswer from './QuestionAnswer';
import QuestionRephrasing from './QuestionRephrasing';
import { uploadMedia } from '../../services/upload';
import MessageMedia from '../MessageMedia';
import Preloader from '../Preloader';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    let result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result = result.map((menuOption, i) => {
        menuOption.displayOrder = (i + 1);
        return menuOption;
    })
    return result;
};

class QuestionItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            addingRephrasing: false,
            savingRephrasing: false,
            addingMenuOption: false,
            savingMenuOption: false,
            editingAnswer: false,
            menuOption: '',
            rephrasing: '',
            question: this.props.question,
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            mediaItem: undefined,
            loadingMediaPreview: false,
            mediaLoading: false,
            savingOrder: false
        };
  
        this.handleAddRephrasingClick = this.handleAddRephrasingClick.bind(this);
        this.handleAddRephrasingSave = this.handleAddRephrasingSave.bind(this);
        this.handleAddRephrasingChange = this.handleAddRephrasingChange.bind(this);
        this.handleRemoveRephrasing = this.handleRemoveRephrasing.bind(this);
        this.handleAddMenuOptionChange = this.handleAddMenuOptionChange.bind(this);
        this.handleAddMenuOptionClick = this.handleAddMenuOptionClick.bind(this);
        this.handleMenuOptionSave = this.handleMenuOptionSave.bind(this);
        this.handleClickAnswer = this.handleClickAnswer.bind(this);
        this.handleBlurAnswer = this.handleBlurAnswer.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleMediaTrigger = this.handleMediaTrigger.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);    
        this.handleReorderToggle = this.handleReorderToggle.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.question !== this.props.question) {
          this.setState({question: this.props.question});
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const question = this.state.question;
        let menuItems = question.menuOptions;

        const items = reorder(
            menuItems,
            result.source.index,
            result.destination.index
        );
        question.menuItems = items;
        this.setState({
            question,
            savingOrder: true
        });

        const menuOptionPayload = this.state.question.menuOptions.map(menuOptionItem => {
            const id = menuOptionItem.question._id
            return {
                ...menuOptionItem,
                question: id
            };
        });

        knowledgeSetService.updateQuestion(this.state.question._id, { menuOptions: menuOptionPayload }, this.props.auth.agent.token).then((question) => {
            this.setState({ savingOrder: false });
            this.props.initKb();
        });
    }

    handleReorderToggle(){
        this.setState(prevProps => ({ editingOrder: !prevProps.editingOrder }));
    }

    handleAddRephrasingClick(){
        this.setState({ addingRephrasing: true });
    }

    handleAddMenuOptionClick(){
        this.setState({ addingMenuOption: true });
    }

    handleAddRephrasingChange(e){
        this.setState({ rephrasing: e.target.value });
    }

    handleAddMenuOptionChange(e){
        this.setState({ menuOption: e.target.value });
    }

    handleAddRephrasingSave(e){
        this.setState({ addingRephrasing: false });
        if(this.state.rephrasing.length > 0 && !this.state.question.question.includes(this.state.rephrasing)){
            this.setState({ savingRephrasing: true })
            const newRephrasings = [...this.state.question.question, this.state.rephrasing];
            knowledgeSetService.updateQuestion(this.state.question._id, { question: newRephrasings }, this.props.auth.agent.token).then((question) => {
                this.setState({ question, savingRephrasing: false });
                this.props.initKb();
            });
        }

        this.setState({rephrasing: ''});
    }

    handleRemoveRephrasing(questionToRemove){
        const newRephrasings = this.state.question.question.filter((question) => question !== questionToRemove);
        this.setState((prevState) => {
            return {
                question: {
                    ...prevState.question,
                    question: newRephrasings
                }
            };
        });
        
        knowledgeSetService.updateQuestion(this.state.question._id, { question: newRephrasings }, this.props.auth.agent.token).then((question) => {
        });
    }

    async handleMenuOptionSave(){
        const questionId = this.state.question._id;
        const menuOption = this.state.menuOption;

        this.setState({ addingMenuOption: false, menuOption: '' });
        
        if(this.state.menuOption.length > 0){
            this.setState({ savingMenuOption: true });
            await this.props.handleMenuOptionSave(questionId, menuOption);
            this.setState({ savingMenuOption: false });
        }
    }

    handleClickAnswer(){
        this.setState({ editingAnswer: true });
    }

    handleAnswerChange(e){
        let question = this.state.question;
        question.answer = e;
        this.setState({ question });
    }

    handleBlurAnswer(){
        if(this.state.editingAnswer){
            this.setState({ editingAnswer: false });
            if(this.state.question.answer.length > 0){
                knowledgeSetService.updateQuestion(this.state.question._id, { answer: this.state.question.answer }, this.props.auth.agent.token).then(() => {
                    this.props.initKb();
                });
            }
        }
    }
    
    handleMediaTrigger(input){
        input.click();
    }

    async handleMediaChange(e){
        this.setState({ hasMedia: true, loadingMediaPreview: true, mediaLoading: true });
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            this.setState({ mediaFile: file }, async () => {
                const mediaFile = this.state.mediaFile;
                if(mediaFile && !mediaFile.url){
                    const uploadResponse = await uploadMedia(this.props.auth.agent.token, mediaFile);
                    if(uploadResponse.url){
                        knowledgeSetService.updateQuestion(this.state.question._id, { media: [{
                            url: uploadResponse.url,
                            name: mediaFile.name,
                            type: mediaFile.type
                        }] }, this.props.auth.agent.token).then(() => {
                            this.props.initKb().then(() => {
                                this.setState({ mediaLoading: false })
                            });
                        });
                    }
                }
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ mediaPreview: e.target.result, loadingMediaPreview: false });
            }

            reader.readAsDataURL(file);
        }
    } 
    
    handleRemoveMedia(e){
        e.stopPropagation();
        this.setState({ mediaLoading: true })
        knowledgeSetService.updateQuestion(this.state.question._id, { media: [] }, this.props.auth.agent.token).then(() => {
            this.props.initKb().then(() => {
                this.setState({ mediaLoading: false })
            });
        });
    }

    handleEmojiPick({ emoji }){
        this.setState((prevState) => {
            return {
                question: {
                    ...prevState.question,
                    answer: prevState.question.answer.substring(0, prevState.question.answer.length - 4) + emoji + '</p>'
                }
            }
        });
    }

    render(){
        return (
            <div className="question_item">
                <div className="question_iten__left question_item__questions">
                    <div className="question_item__questions__rephrasings">
                        {this.state.question.question.map((questionPhrasing, index) => {
                            return <QuestionRephrasing key={index} questionPhrasing={questionPhrasing} question={this.state.question} handleRemoveRephrasing={this.handleRemoveRephrasing}/>
                        })}
                    </div>                    
                    {this.state.savingRephrasing && <div className="question_item__questions_rephrasing">Saving...</div>}
                    {this.state.addingRephrasing && <input autoFocus value={this.state.rephrasing} onBlur={this.handleAddRephrasingSave} onChange={this.handleAddRephrasingChange} type="text" placeholder="Enter a question rephrasing"/>}
                    <div className="question_item__action_button" onClick={this.handleAddRephrasingClick}><HiPlusCircle/> Add rephrasing</div>
                </div>
                <div className="question_iten__right">
                    {this.state.question.tags && this.props.showTags && this.state.question.tags.length > 0 && <div className="question_tags">Tags:<div className="question_tags__inner">{this.state.question.tags.map(tag => <div className="tag">{tag.label}</div>)}</div></div>}
                    {!this.state.mediaLoading ? (
                        this.state.question.media && this.state.question.media.length > 0 && <MessageMedia thumbnail={true} media={this.state.question.media[0]} handleMediaClick={this.props.handleMediaClick} onMediaRemove={this.handleRemoveMedia}/>
                    ) : (<Preloader/>)}
                    <QuestionAnswer handleMediaTrigger={() => { this.handleMediaTrigger(this.mediaUploadElement) }} handleEmojiPick={this.handleEmojiPick} handleClickAnswer={this.handleClickAnswer} handleAnswerChange={this.handleAnswerChange} handleBlurAnswer={this.handleBlurAnswer} question={this.state.question} editingAnswer={this.state.editingAnswer}/>
                    {(this.state.question.menuOptions && this.state.question.menuOptions.length > 0) && (
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div className="question_item__menu_items" disabled={this.state.savingOrder} ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                    {this.state.question.menuOptions.map((menuOption, index) => {
                                        let showOption = true;

                                        if(this.props.selectedTagFilters && this.props.selectedTagFilters.length > 0){
                                            this.props.selectedTagFilters.forEach(filterOption => {
                                                const filterOptionId = filterOption.value._id;
                                                const containsFilter = menuOption.question.tags.find(tag => tag._id === filterOptionId);
                                                if(!containsFilter){ 
                                                    showOption = false;
                                                }
                                            });
                                        }

                                        if(showOption){
                                            return (
                                                <Draggable isDragDisabled={!this.state.editingOrder || this.state.savingOrder} key={menuOption.question._id} draggableId={menuOption.question._id} index={index}>
                                                {(provided, snapshot) => (
                                                <div ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps} className="question_item__menu_items__item" onClick={!this.state.editingOrder ? () => { this.props.onMenuOptionClick(menuOption.question._id) } : () => {}}>
                                                    <div className="question_item__menu_items__label">{this.state.editingOrder && <FiMove className="reorder-icon"/>}{menuOption.question.question[0]}</div>
                                                    {this.props.showTags && menuOption.question.tags && menuOption.question.tags.length > 0 && (
                                                        <div className="question_tags">
                                                            <div className="question_tags__inner">{menuOption.question.tags.map(tag => <div key={tag._id} className="tag">{tag.label}</div>)}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                )}
                                                </Draggable>
                                                );
                                        }else{
                                            return '';
                                        }
                                    })}            
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}      
                    {this.state.savingMenuOption && <div className="question_item__menu_items__item">Saving...</div> }
                    {this.state.addingMenuOption && <TextareaAutosize autoFocus className="menu_option_field" value={this.state.menuOption} onBlur={this.handleMenuOptionSave} onChange={this.handleAddMenuOptionChange} type="text" placeholder="Enter menu option text"/>}                      
                    {(!this.state.savingMenuOption && !this.state.addingMenuOption) && <div className="question_item__action_button" onClick={this.handleAddMenuOptionClick}><HiPlusCircle/> Add menu option</div>}
                    {(this.state.addingMenuOption) && <div className="question_item__action_button" onClick={this.handleMenuOptionSave}><HiOutlineSave/> Save option</div>}
                </div>
                                
                {this.state.question.menuOptions.length > 0 && (this.state.editingOrder ? <div className="question_iten__action cg" onClick={this.handleReorderToggle} style={{ color: 'green' }} title="Stop Reordering"><HiCheck/></div> : <div className="question_iten__action cg" onClick={this.handleReorderToggle} title="Reorder Menu Items"><CgMoveTask/></div>)}
                {this.props.filterTagOptions.length > 0 && <div className="question_iten__action" title="Mangage Tags" onClick={() => { this.props.handleTriggerTagsModal(this.state.question._id) }}><HiOutlineTag/></div>}
                {(['super_user', 'admin'].includes(this.props.auth.agent.agent.role) || this.state.question.owner === this.props.auth.agent.agent._id) && <div className="question_iten__action" title="Delete Question" onClick={() => { this.props.handleTriggerDeleteModal(this.state.question._id) }}><HiOutlineTrash/></div>}
                <input ref={input => this.mediaUploadElement = input} type="file" style={{display: 'none'}} onChange={this.handleMediaChange}/>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(QuestionItem);