import moment from 'moment';

const toTimestamp = (dateArg) => {
    const today = moment();
    const date = moment(dateArg);
    if(today.isSame(date, 'day', 'month')){
        return date.format('hh:mmA');
    }
    
    return date.format('Do MMMM, YYYY');
}

const toTimeOnly = (dateArg) => {
    const date = moment(dateArg);
    return date.format('hh:mmA');
};

const toDateOnly = (dateArg) => {
    const date = moment(dateArg);
    return date.format('DD/MM/YYYY');
};

const toWordDate = (dateArg) => {
    const date = moment(dateArg);
    return date.format('Do MMMM, YYYY');
};

export { toTimestamp, toTimeOnly, toDateOnly, toWordDate }