function handleResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getInitials(name){
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
}

function titleCase(str) {
    str = str.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    
    return str.join(' ');
}

function bold(text){
    var bold = /\*(.*?)\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');            
    return html;
}

function italic(text){
    var italic = /_(.*?)_/gm;
    var html = text.replace(italic, '<i>$1</i>');            
    return html;
}    

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

export { handleResponse, getInitials, titleCase, bold, italic, makeid };