function login(email, password){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch('/agents/login', requestOptions).then(handleResponse).then((agent) => {
        localStorage.setItem('user', JSON.stringify(agent));
        return agent;
    });
}

function validatePassword(email, password){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch('/agents/login', requestOptions).then(handleResponse).then(({ agent }) => {
        return agent;
    }).catch(e => {
        throw e;
    });
}

function resetPassword(email){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch('/agents/password-reset', requestOptions).then(handleLoginResponse).then((success) => {
        return success;
    });
}

function validatePasswordReset(token){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch('/agents/password-reset/validate', requestOptions).then(handleLoginResponse).then((agent) => {
        localStorage.setItem('user', JSON.stringify(agent));
        return agent;
    });
}


function setPassword(authToken, password){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ password, password_set: true })
    };

    return fetch('/agents/password', requestOptions).then(handleResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify(currentAgent));
        return agent;
    });
}

function setAgent(authToken, agentId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/agents/' + agentId, requestOptions).then(handleResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify(currentAgent));
        return agent;
    });
}

function updateAgent(authToken, { _id, first_name, last_name, email, companies, role}){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ first_name, last_name, email, companies, role})
    };
    return fetch('/agents/' + _id, requestOptions).then(handleResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify(currentAgent));
        return agent;
    });
}

function setAvatar(authToken, agentId, fileObject){
    var data = new FormData()
    data.append('avatar', fileObject)

    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken },
        body: data
    };

    return fetch('/agents/' + agentId + '/avatar', requestOptions).then(handleResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify(currentAgent));
        return agent;
    });
}

function setStatus(authToken, agentId, status){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ status })
    };

    return fetch('/agents/' + agentId + '/status', requestOptions).then(handleResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify(currentAgent));
        return agent;
    });
}

function logout(){
    localStorage.removeItem('user');
    localStorage.removeItem('selectedChatbot');
    localStorage.removeItem('selectedCompany');
}

function getAgentKnowledgeSets(agentId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/agents/' + agentId + '/kbs', requestOptions).then(handleResponse).then((kbs) => {
        return kbs;
    });
}

function getAgent(agentId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/agents/' + agentId, requestOptions).then(handleResponse).then((agent) => {
        return agent;
    });
}

function handleResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            if(response.status === 400){
                logout();
            }
            
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleLoginResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            if(response.status === 400){
                logout();
            }
            
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export const agentService = {
    login,
    logout,
    setPassword,
    getAgentKnowledgeSets,
    setAvatar,
    setAgent,
    setStatus,
    updateAgent,
    getAgent,
    resetPassword,
    validatePassword,
    validatePasswordReset
};