import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class MenuItem extends React.Component{
    render(){
        return (
            <div className="dashboard_menu__item">
                {this.props.rolesAllowed.includes(this.props.auth.agent.agent.role) && <NavLink className="dashboard_menu__item" activeClassName="active" to={this.props.to}>{this.props.children}</NavLink>}
            </div>
        );
    }
}

const mapStateToProps = (state) => (state);

export default connect(mapStateToProps)(MenuItem);