import KbChatMessage from '../Kbs/KbChatMessage';
import ScrollableFeed from 'react-scrollable-feed';

const KbChatMessages = (props) => {
    return (
        <ScrollableFeed className="kb_chat_messages">
            {props.messages.map((message) => <KbChatMessage selectedTagFilters={props.selectedTagFilters} handlePromptClick={props.handlePromptClick} key={message.id} message={message}/>)}
        </ScrollableFeed>
    );
};  

export default KbChatMessages;