import { alertActions } from './alerts';
import { agentService } from '../services/agents';
import { history } from '../helpers/history';

function login(email, password){
    return (dispatch) => {
        dispatch(request({ email }));

        agentService.login(email, password).then((agent) => {
            dispatch(success(agent));
            history.push('/knowledge-set');
        }, (error) => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        });
    };

    function request(agent){ 
        return {
            type: 'AGENT_LOGIN_REQUEST',
            agent
        } 
    }

    function success(agent){ 
        return {
            type: 'AGENT_LOGIN_SUCCESS',
            agent
        } 
    }

    function failure(error){ 
        return {
            type: 'AGENT_LOGIN_FAILURE',
            error
        } 
    }
};

function validatePasswordReset(token){
    return (dispatch) => {
        agentService.validatePasswordReset(token).then((agent) => {
            dispatch(success(agent));
            history.push('/');
        }, (error) => {
            console.log(error)
            dispatch(failure(error))
            dispatch(alertActions.error(error))
            history.push('/login');
        })
    };

    function success(agent){ 
        return {
            type: 'AGENT_LOGIN_SUCCESS',
            agent
        } 
    }

    function failure(error){ 
        return {
            type: 'AGENT_LOGIN_FAILURE',
            error
        } 
    }
};

function logout() {
    agentService.logout();
    return { type: 'AGENT_LOGOUT' };
}

function setPassword(authToken, password){
    return (dispatch) => {
        dispatch(request());
        
        agentService.setPassword(authToken, password).then((agent) => {
            dispatch(success(agent));
            history.push('/');
        }, (error) => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        });
    }

    function request(){ 
        return {
            type: 'AGENT_PASSWORD_SET_REQUEST'
        } 
    }

    function success(agent){ 
        return {
            type: 'AGENT_PASSWORD_SET_SUCCESS',
            agent
        } 
    }

    function failure(error){ 
        return {
            type: 'AGENT_PASSWORD_SET_FAILURE',
            error
        } 
    }
}

function setAvatar(authToken, id, file){
    return agentService.setAvatar(authToken, id, file).then((agent) => {
        return agent;
    });
}

function setCurrentAgent(agent){
    return {
        type: 'SET_CURRENT_AGENT_SET',
        agent
    }
}

function setCurrentCompany(companyId){
    localStorage.setItem('selectedCompany', companyId)
    return {
        type: 'SET_CURRENT_COMPANY',
        companyId
    }
}

function setCurrentChatbot(chatbot){
    localStorage.setItem('selectedChatbot', JSON.stringify(chatbot))
    return {
        type: 'SET_CURRENT_CHATBOT',
        chatbot
    }
}

export const agentActions = {
    login,
    logout,
    setPassword,
    setCurrentCompany,
    setCurrentChatbot,
    setAvatar,
    setCurrentAgent,
    validatePasswordReset
}; 