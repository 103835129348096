import { toTimestamp } from '../../services/dates';
import { NavLink } from 'react-router-dom';

const KbsListItem = (props) => {
    const kb = props.kb;
    return (
        <NavLink className="kbs_list_item" to={`/knowledge-set/${kb._id}`}>
            <div className="kbs_list_item__name">{kb.name}</div>
            <div className="timestamp">{toTimestamp(kb.updatedAt)}</div>
        </NavLink>
    );
};

export default KbsListItem;