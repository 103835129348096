import React from 'react';
import { connect } from 'react-redux';

class DashboardSelectedComponent extends React.Component{
    render(){
        return (
            <div className="dashboard_component">
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(DashboardSelectedComponent);