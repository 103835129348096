import Modal from 'react-modal';
import Preloader from '../Preloader';

const KbPreloaderModal = (props) => {
    return (
        <Modal isOpen={props.shown} className="modal modal__preloader" onRequestClose={props.handleTriggerPublishModal} ariaHideApp={false}>
            <div className="modal__title">{props.title}</div>
            <div className="modal__description">{props.description}</div>
            <Preloader/>
        </Modal>
    );
}

export default KbPreloaderModal;