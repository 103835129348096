import React from 'react';
import { connect } from 'react-redux';
import KbsList from './KbsList';
import { agentService } from '../../services/agents';

class Kbs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            kbs: [],
            loadingKbs: true
        };
    }

    componentDidMount(){
        agentService.getAgentKnowledgeSets(this.props.auth.agent.agent._id, this.props.auth.agent.token).then((kbs) => {
            this.setState({
                loadingKbs: false,
                kbs
            });
        });
    }

    render(){
        return(
            <div className="container container_boxed">
                <div className="col-12">
                    <div className="hero_title">Knowledge Sets</div>
                    <KbsList loadingKbs={this.state.loadingKbs} kbs={this.state.kbs}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Kbs);