import React from 'react';
import { getInitials } from '../../services/misc';
import Preloader from '../Preloader';
import AgentPopup from './AgentPopup';

export default class AgentProfileAvatar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showPopup: false
        }

        this.handleHover = this.handleHover.bind(this);
    }

    handleHover(showPopup){
        this.setState({showPopup})
    }
    render(){
        const agent = this.props.agent;
        const agentFullName = `${agent.first_name} ${agent.last_name}`;
        const agentInitials = getInitials(agentFullName);
        const width = this.props.size + 'px' || undefined;
        const height = this.props.size + 'px' || undefined;
        const fontSize = this.props.size / 3 || undefined;
        const avatar = agent.avatar || undefined;
        const popupEnabled = this.props.popupEnabled || false;
        
        return (
            <div className="avatar-container">          
                <div className="agent_profile_avatar" style={{ width, height }} onMouseEnter={() => { this.handleHover(true) }} onMouseLeave={() => { this.handleHover(false) }}>{this.props.changingAvatar && <Preloader fitToContent={true} color="white"/> }{avatar ? <img alt="Agent Avatar" src={'data:image/jpg;base64,' + avatar}/> : <div className="agent_initials" style={{ fontSize }}>{agentInitials}</div>}</div>
                {popupEnabled && this.state.showPopup &&  <AgentPopup agent={agent} onMouseEnter={() => { this.handleHover(true) }} onMouseLeave={() => { this.handleHover(false) }}/>} 
            </div>
        )
    }
}