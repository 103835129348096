import React from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import StatusText from './StatusText';


export default class StatusSelector extends React.Component{
    constructor(props){
        super(props);
        this.agent = this.props.agent;
        this.state = {
            menuShown: false
        }

        this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }

    handleMenuToggle(){
        this.setState((prevState) => {
            return {
                menuShown: !prevState.menuShown
            }
        });
    }

    render(){
        return (
            <div className="status_selector" onClick={this.handleMenuToggle}>
                <StatusText status={this.agent.agent_settings.status}/><HiOutlineChevronDown className="chevron"/>
                {this.state.menuShown && (
                    <div className="popup_box">               
                        <div className="status_selector__item" onClick={(e) => { this.props.onChange(e, 'available') }}><StatusText status="available"/></div> 
                        <div className="status_selector__item" onClick={(e) => { this.props.onChange(e, 'busy') }}><StatusText status="busy"/></div> 
                    </div>
                )}
            </div>
        );
    };
}