import React from "react";
import { connect } from 'react-redux';
import { bold, italic } from '../../services/misc';
import onClickOutside from "react-onclickoutside";
import EmojiPicker from '../Base/EmojiPicker';
import { RiImageAddFill } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class QuestionAnswer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cursorIndex: 0
        };

        this.handleCursorFocus = this.handleCursorFocus.bind(this);
    }
    handleClickOutside = evt => {
        this.props.handleBlurAnswer();
    };

    handleCursorFocus(e){
        if(e){
            this.setState({
                cursorIndex: e.index
            })
        }
    }

    modules = {
        toolbar: [
          ['bold', 'italic']
        ]
      }

    render(){
        this.props.question.answer.replace(/\[file=".*?"]/gm, '');
        return (
            <div className="question_item__answer" title="Click to edit" onClick={this.props.handleClickAnswer}>{this.props.editingAnswer ? (
                <div className="question_item__answer__inner">
                    <div className="answer_action" title="Add Attachment" onClick={this.props.handleMediaTrigger}><RiImageAddFill/></div>
                    <ReactQuill theme="snow" value={this.props.question.answer} onChangeSelection={this.handleCursorFocus} onChange={this.props.handleAnswerChange} modules={this.modules}/>
                    <EmojiPicker handleEmojiPick={(e, emoji) => { this.props.handleEmojiPick(emoji, this.state.cursorIndex) }}/>
                </div>
            ) : <div dangerouslySetInnerHTML={{ __html: bold(italic(this.props.question.answer)) }}/>}</div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(QuestionAnswer));