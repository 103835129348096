import { connect } from 'react-redux';
import React from 'react';
import { agentActions } from '../../actions/agents';
import AlertBox from '../../components/AlertBox';
import { validatePassword } from '../../services/password';

class SetPasswordForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            password: '',
            passwordConfirm: '',
            isValidPassword: false,
            submitted: false,
            passwordError: undefined
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        const { name, value } = e.target;
                
        this.setState({
            [name]: value
        }); 
    }

    handleSubmit(e){
        e.preventDefault();

        this.setState({
            submitted: true
        });

        const { password } = this.state;
        const { dispatch } = this.props;
        if( password ){
            dispatch(agentActions.setPassword(this.props.auth.agent.token, password));
        }
    }

    render(){
        let passwordValid = validatePassword(this.state.password, this.state.passwordConfirm);
        return(
            <div className="login_form">
                <h2>Set Password</h2>
                <p>You have logged in with a temporary password. Please set a permanent password to login with.</p>
                <form className="login_form__form" name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Password</label>
                        <input disabled={this.props.auth.settingPassword} type="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="username" placeholder="Enter your password"/>
                        {this.state.submitted && !this.state.password && <div className="help-block">Password is required</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Confirm Password</label>
                        <input disabled={this.props.auth.settingPassword} type="password" name="passwordConfirm" value={this.state.passwordConfirm} onChange={this.handleChange} autoComplete="current-password" placeholder="Confirm your password"/>
                        {this.state.submitted && !this.state.passwordConfirm && <div className="help-block">Password confirmation is required</div>}
                    </div>
                    <div className="form-group">
                        <button disabled={!passwordValid.isValid || this.props.auth.settingPassword} className="button">{this.props.auth.settingPassword ? 'Saving Password...' : 'Set Password'}</button>
                    </div>
                    {(!passwordValid.isValid && passwordValid.message) && <AlertBox type="alert-danger">{passwordValid.message}</AlertBox>}
                </form>
            </div>
        );
    }
};

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(SetPasswordForm);