import Modal from 'react-modal';

const KbPublishModal = (props) => {
    return (
        <Modal isOpen={props.confirmPublishOpen} className="modal" ariaHideApp={false}>
            <div className="modal__title">Publish your changes</div>
            <div className="modal__content">You are about to publish your changes, please make sure you have tested your new questions.<br/><br/>This cannot be undone.</div>
            <div className="modal__actions">
                <button className="button button_fit cancel" onClick={props.handleTriggerPublishModal}>Cancel</button>
                <button className="button button_fit" onClick={props.handlePublishChanges}>Publish</button>
            </div>
        </Modal>
    );
}; 

export default KbPublishModal;