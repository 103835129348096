import { toTimestamp } from '../../services/dates';
import MessageMedia from '../MessageMedia';

const KbChatMessage = (props) => {
    return (
        <div className={`kb_chat__message message ${props.message.type}`}>
            <div className="kb_message__buble">
                {props.message.media && <MessageMedia media={props.message.media}/>}
                <div className="message__text">{props.message.message}</div>
                {(props.message.prompts && props.message.prompts.length > 0) && <div className="message__prompts">
                    {props.message.prompts.map((prompt, index) => {
                        let showOption = true;
                        if(prompt.filters){
                            if(props.selectedTagFilters && props.selectedTagFilters.length > 0){
                                props.selectedTagFilters.forEach(filterOption => {
                                    const containsFilter = prompt.filters.find(tag => tag.trim().toLowerCase() === filterOption.label.trim().toLowerCase());
                                    if(!containsFilter){ 
                                        showOption = false;
                                    }
                                });
                            }
                        }

                        return showOption ? <div key={index} className="message_prompt" onClick={() => { props.handlePromptClick(prompt.qnaId, prompt.displayText) }}>{prompt.displayText}</div> : "";
                    })}
                </div>}
            </div>
            <div className="timestamp">{toTimestamp(props.message.createdAt)}</div>
        </div>
    );
};

export default KbChatMessage;