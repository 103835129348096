import React from 'react';
import Preloader from '../Preloader';
import validator from 'validator';
import { agentService } from '../../services/agents';

class PasswordResetForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            email: '',
            submitted: false,
            submitting: false
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        const { name, value } = e.target;
                
        this.setState({
            [name]: value
        }); 
    }

    async handleSubmit(e){
        e.preventDefault();

        this.setState({
            submitting: true
        });
        
        await agentService.resetPassword(this.state.email);
        this.setState({
            submitting: false,
            submitted: true
        });
    }

    render(){
        return(
            <div className="login_form">
                {!this.state.submitted ? (
                    <div>
                        <h2>Reset Password</h2>
                        <p>Enter the email address of your account and we will send you an email with a password reset link.</p>
                        <form className="login_form__form" name="form" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input disabled={this.state.submitting} type="email" name="email" value={this.state.password} onChange={this.handleChange} autoComplete="email" placeholder="Enter your email"/>
                            </div>
                            <div className="form-group">
                                <button disabled={this.state.submitting || !validator.isEmail(this.state.email)} className="button">{this.state.submitting ? <Preloader size="15" color="white" padding="0"/> : 'Reset Password'}</button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <h2>Check Your Email</h2>
                        <p>A password reset link has been sent to your email address.<br/><br/>If you cannot find this email in your inbox. Check your spam folder.</p>
                        <button disabled={this.state.submitting || !validator.isEmail(this.state.email)} className="button">{this.state.submitting ? <Preloader size="15" color="white" padding="0"/> : 'Resend Link'}</button>
                    </div>
                )}
            </div>
        );
    }
};

export default PasswordResetForm;