import React from 'react';
import { connect } from 'react-redux';
import MenuItem from './MenuItem';
import { agentActions } from '../../actions/agents';

class Menu extends React.Component{
    constructor(props){
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        this.props.dispatch(agentActions.logout());
    }

    render(){
        return (
            <div className="dashboard_menu">
                <div className="dashboard_menu__inner_top">
                    <img className="dashboard_menu__logo" src="/images/logo_full.webp" alt="Verge AI Logo"/>
                    <MenuItem rolesAllowed={['admin', 'agent', 'team_user', 'super_user']} to="/knowledge-set">Knowledge Sets</MenuItem>
                </div>
                <div className="dashboard_menu__inner_bottom">
                    <button className="dashboard_menu__item" onClick={this.handleLogout}>Logout</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Menu);