import React from 'react';
import KbChatMessageBar from './KbChatMessageBar';
import KbChatMessages from './KbChatMessages';
import moment from 'moment';
import Preloader from '../Preloader';
import { v4 as uuidv4 } from 'uuid';
import { knowledgeSetService } from '../../services/knowledgeSets';
import { connect } from 'react-redux';
import { HiOutlineX } from 'react-icons/hi';
import { VscDebugRestart } from 'react-icons/vsc';
import { bold, italic } from '../../services/misc';

class KbChat extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messages: [],
            messageValue: ''
        };

        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleMessageSend = this.handleMessageSend.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handlePromptClick = this.handlePromptClick.bind(this);
        this.handleChatState = this.handleChatState.bind(this);
    }

    handleMessageChange(e){
        this.setState({
            messageValue: e.target.value
        });
    }

    handlePromptClick(qnaId, buttonValue){
        this.sendMessage(buttonValue, qnaId);
    }

    handleMessageSend(e){
        e.preventDefault();
        this.sendMessage(this.state.messageValue);
        this.setState({
            messageValue: ''
        });
    }
    
    sendMessage(messageText, qnaId = null){
        const message = {
            id: uuidv4(),
            message: messageText,
            type: 'user',
            createdAt: moment()
        };

        let reply = {
            id: uuidv4(),
            message: <div className="message-preloader">Loading <Preloader size="20" padding="0 0.3rem"/></div>,
            type: 'reply',
            createdAt: moment()
        };

        knowledgeSetService.getQuestionAnswer(this.props.kb, messageText, this.props.auth.agent.token, qnaId).then((answer) => {
            const index = this.state.messages.findIndex((message) => message.id === reply.id);
            const messages = this.state.messages;

            const media = answer.answers[0].answer.match(/file=".*?"]/g);
            const messageMedia = [];
            if(media){
                messageMedia.push(JSON.parse(media[0].substring(6, media[0].length - 2)));
                reply.media = messageMedia[0];
            }

            answer.answers[0].answer = answer.answers[0].answer.replace(/\[file=".*?"]/gm, '');

            let prompts = answer.answers[0].dialog && answer.answers[0].dialog.prompts && answer.answers[0].dialog.prompts.length > 0 ? answer.answers[0].dialog.prompts : undefined;
            
            if(prompts){
                prompts = prompts.map(prompt => {
                    if(prompt.displayText.indexOf("{\"filters\":") > -1){
                        const filters = prompt.displayText.substring(prompt.displayText.indexOf("{\"filters\":"));
                        prompt.displayText = prompt.displayText.substring(0, prompt.displayText.indexOf("{\"filters\":")).trim();
                        if(filters && filters !== ""){
                            try{
                                const filtersArray = JSON.parse(filters);
                                prompt.filters = filtersArray.filters;
                            }catch(e){
    
                            }
                        }
                    }

                    return prompt;
                });
            }

            reply.message = <div dangerouslySetInnerHTML={{ __html: bold(italic(answer.answers[0].answer)) }}/>;
            reply.prompts = prompts;
            message[index] = reply;
            this.setState({ messages });
        });
        
        this.setState((prevState) => {
            return {
                messages: [...prevState.messages, message, reply]
            };
        });
    } 

    handleChatState(){
        this.setState({ messages: [] });
        this.sendMessage('Menu');
    }

    render(){
        return (
            <div className="kb_chat">
                <div className="kb_chat__title">Test Knowledge Set <div className="kb_chat__title__actions">{this.state.messages.length > 0 && <div className="close_chat" onClick={this.handleChatState}><VscDebugRestart/></div>}<div className="close_chat" onClick={this.props.handleChatToggle}><HiOutlineX/></div></div></div>
                {this.props.selectedTagFilters.length > 0 && <div className="kb_chat__tags_applied"><label>Tags Applied:</label><div className="question_tags__inner">{this.props.selectedTagFilters.map(tag => <div className="tag">{tag.label}</div>)}</div></div>}
                <KbChatMessages selectedTagFilters={this.props.selectedTagFilters} handlePromptClick={this.handlePromptClick} messages={this.state.messages}/>
                <KbChatMessageBar handleChatState={this.handleChatState} messages={this.state.messages} handleMessageSend={this.handleMessageSend}  handleMessageChange={this.handleMessageChange} messageValue={this.state.messageValue}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(KbChat);