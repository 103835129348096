import React from 'react';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
import Preloader from '../Preloader';

export default class LoadMore extends React.Component {
    constructor(props){
        super(props);
        this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    }

    handleLoadMoreClick(){
        this.props.onClick();
    }

    render(){
        const icon = this.props.icon || <HiOutlineChevronDoubleDown/>
        return (
            <div className="load_more" onClick={this.handleLoadMoreClick}>
                <div className="load_more__button" title="Load More">
                    {!this.props.loadingMore ? icon : <Preloader/>}
                </div>
            </div>
        );
    }
}