import Modal from 'react-modal';

const KbDeleteModal = (props) => {
    return (
        <Modal isOpen={props.confirmDeleteOpen} className="modal" onRequestClose={() => { props.handleTriggerDeleteModal(undefined) }} ariaHideApp={false}>
            <div className="modal__title">Delete Question</div>
            <div className="modal__content">You are about to delete this question. This will delete any questions within the menu options of this question and all its children. Please note this action cannot be undone.<br/><br/>Are you sure you want to delete this question?</div>
            <div className="modal__actions">
                <button className="button button_fit cancel" onClick={() => { props.handleTriggerDeleteModal(undefined) }}>Cancel</button>
                <button className="button button_fit" onClick={props.handleDeleteQuestion}>Delete</button>
            </div>
        </Modal>
    );
}; 

export default KbDeleteModal;