import Modal from 'react-modal';

const KbAlertModal = (props) => {
    return (
        <Modal isOpen={props.shown} className="modal modal__preloader" onRequestClose={props.handleTriggerAlert} ariaHideApp={false}>
            <div className="modal__title">{props.title}</div>
            <div className="modal__content">{props.description}</div>
            <div className="modal__actions">
                <button className="button button_fit cancel" onClick={props.handleTriggerAlert}>Done</button>
            </div>
        </Modal>
    );
}

export default KbAlertModal;