import React from 'react';
import { connect } from 'react-redux';

class ChangeAvatar extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        const agent = this.props.auth.agent.agent;
        return (
            <div className="avatar_actions">
                {agent.avatar ? 
                    <div className="avatar_actions__inner">
                        <div className="avatar_action" onClick={() => { this.props.handleImageSelect(this.inputElement) }}>Change</div>
                        <div className="avatar_action remove">Remove</div>
                    </div> : 
                    <div className="avatar_actions__inner">
                        <div className="avatar_action" onClick={() => { this.props.handleImageSelect(this.inputElement) }}>Upload Profile Picture</div>
                    </div>}
                <input ref={input => this.inputElement = input} type="file" style={{display: 'none'}} onChange={this.props.handleImageChanged}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(ChangeAvatar);