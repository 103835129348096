import { isVideo, isImage } from '../services/upload';
import { HiPlay, HiOutlineX } from 'react-icons/hi'
import {  HiOutlineDocumentText } from 'react-icons/hi';
import React from "react";
import ChatMediaViewer from './ChatMediaViewer';

class MessageMedia extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mediaItem: undefined
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);
    }

    handleMediaClick(media){
        this.setState({ mediaItem: media }, () => {
            if(this.state.mediaItem && (!isVideo(this.state.mediaItem.type) && !isImage(this.state.mediaItem.type))){
                window.open(media.url, "_blank")
            }
        });
    }
    
    render(){
        return (
            <div className={!this.props.thumbnail ? 'message_media_item__outer' : ''} >
                <div className="message_media_item" onClick={() => { this.handleMediaClick(this.props.media) }}>
                    {isImage(this.props.media.type) && <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><img src={this.props.media.url} alt="Message Media"/></div>}
                    {isVideo(this.props.media.type) && <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><video src={this.props.media.url} alt="Message Media"></video><div className="message_media_item__play"><HiPlay/></div></div>}
                    {(!isImage(this.props.media.type) && !isVideo(this.props.media.type)) && <div className="message_media_item__other" title={this.props.media.name}><HiOutlineDocumentText/><span className="text-truncate">{this.props.media.name}</span></div>}
                    {this.props.onMediaRemove && <div className="message_media_remove" onClick={this.props.onMediaRemove}><HiOutlineX/></div>}
                </div>
                {this.state.mediaItem && (isVideo(this.state.mediaItem.type) || isImage(this.state.mediaItem.type)) && <ChatMediaViewer media={this.state.mediaItem} handleMediaClick={this.handleMediaClick}/>}
            </div>
        );
    }
}

export default MessageMedia;