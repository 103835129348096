import React from 'react';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';

export default class QuestionSearch extends React.Component{
    render(){
        return (
            <div className="question_search">
                <form className="question_search_input_holder" onSubmit={this.props.handleQuestionSearchSubmit}>
                    <input className="question_search__input" disabled={this.props.loadingQuestions} type="text" onChange={this.props.handleQuestionSearchChange} value={this.props.searchTerm} placeholder="Search for question..."/>
                    <div className="search_actions">
                        {this.props.isSearched && <div className="search_actions__action" onClick={this.props.clearSearch}><HiOutlineX/></div>}
                        <button className="search_actions__action"><HiOutlineSearch/></button>
                    </div>
                </form>
                {(this.props.isSearching) && <div>Searching...</div>}
                {(!this.props.isSearching && this.props.isSearched && this.props.termSearched) && <div className="question_search__term">Showing {this.props.questions.length} results for {this.props.termSearched}</div>}
            </div>
        );
    }
}