import { handleResponse } from './misc';

function getKnowledgeSet(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId, requestOptions).then(handleResponse).then((kb) => {
        return kb;
    });
}

function getKnowledgeSetQuestions(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId + '/questions', requestOptions).then(handleResponse).then((questions) => {
        return questions;
    });
}

function getQuestion(questionId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/questions/' + questionId, requestOptions).then(handleResponse).then((questions) => {
        return questions;
    });
}

function addQuestion(question, authToken){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(question)
    };

    return fetch('/questions', requestOptions).then(handleResponse).then((question) => {
        return question;
    });
}

function updateQuestion(questionId, updates, authToken){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(updates)
    };

    return fetch('/questions/' + questionId, requestOptions).then(handleResponse).then((question) => {
        return question;
    })
}

function searchQuestions(kbId, searchTerm, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId + '/questions/search/' + searchTerm, requestOptions).then(handleResponse).then((questions) => {
        return questions;
    })
}

function getQuestionAnswer(kbId, searchTerm, authToken, qnaId = null){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = "";
    if(qnaId){
        queryString += "?qnaId=" + qnaId;
    }

    return fetch('/kbs/' + kbId + '/qna/query/' + searchTerm + queryString, requestOptions).then(handleResponse).then((questions) => {
        return questions;
    });
}

function deleteQuestion(questionId, authToken){
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/questions/' + questionId, requestOptions).then(handleResponse).then((question) => {
        return question;
    });
}

function saveToQna(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId + '/qna/export', requestOptions).then(handleResponse).then((questions) => {
        return questions;
    });
}

function notifyKb(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId + '/qna/notify', requestOptions).then(handleResponse).then(() => {
        return true;
    });
}

function publishToQna(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/kbs/' + kbId + '/qna/publish', requestOptions).then(handleResponse).then((questions) => {
        return questions;
    });
}

function getAuditTrail(kbId, authToken, limit){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    return fetch('/kbs/' + kbId + '/audit' + queryString, requestOptions).then(handleResponse).then((auditTrail) => {
        return auditTrail;
    });
}

function getKbTags(kbId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/tags/' + kbId, requestOptions).then(handleResponse).then((tags) => {
        return tags;
    });
}

function exportKbs(authToken, kbId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/kbs/' + kbId + '/export_questions', requestOptions).then((questions) => {
        return questions;
    });
}

export const knowledgeSetService = {
    getKnowledgeSet,
    getKnowledgeSetQuestions,
    getQuestion,
    updateQuestion,
    addQuestion,
    searchQuestions,
    getQuestionAnswer,
    saveToQna,
    publishToQna,
    deleteQuestion,
    notifyKb,
    getAuditTrail,
    getKbTags,
    exportKbs
};