import Preloader from '../Preloader';
import KbsListItem from './KbsListItem';

const KbsList = (props) => {
    return (
        <div className="kbs_list">
            {!props.loadingKbs ? props.kbs.map((kb) => <KbsListItem key={kb._id} kb={kb}/>) : <Preloader fitToContent={true}/>}
        </div>
    );
};

export default KbsList;