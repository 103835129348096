import React from 'react';
import KbsBreadcrumbs from '../Kbs/KbsBreadcrumbs';
import AddQuestionForm from './AddQuestionForm';
import QuestionItem from './QuestionItem';
import { FiPlus } from 'react-icons/fi';
import Preloader from '../Preloader';
import Select from 'react-select';
import { HiOutlineTag, HiOutlineX } from 'react-icons/hi';

export default class QuestionList extends React.Component{
    render(){
        return (
            <div className={'question_list__panel ' + this.props.className}>
                {!this.props.loadingQuestions ? (
                    <div className="container container_boxed question_list">
                        <KbsBreadcrumbs handleBreadcrumbBackClick={this.props.handleBreadcrumbBackClick} handleBreadcrumbItemClick={this.props.handleBreadcrumbItemClick} selectedQuestion={this.props.selectedQuestion} questionHistory={this.props.questionHistory}/>
                        {this.props.filterTagOptions && this.props.filterTagOptions.length > 0 && (
                            <div>
                                <label>Tag</label>
                                <Select className="select" options={this.props.filterTagOptions} isMulti value={this.props.selectedTagFilters} onChange={this.props.handleTagFilterChange}/>
                            </div>
                        )}
                        <div className="question_list_legend"><div className="question_iten__left">Question</div><div className="question_iten__right answer_heading">Answer
                        <div className="question_legend_actions">
                            {this.props.filterTagOptions.length > 0 && (this.props.showTags ? <div className="kb_page_top_bar__action" onClick={() => { this.props.handleToggleShowTags(false) }}><HiOutlineX/><span>Hide Tags</span></div> : <div className="kb_page_top_bar__action" onClick={() => { this.props.handleToggleShowTags(true) }}><HiOutlineTag/><span>Show Tags</span></div>)}
                            {!this.props.selectedQuestion && <div className="kb_page_top_bar__action" onClick={() => { this.props.handleAddQuestionToggle(true) }}><FiPlus/><span>Add Question</span></div>}
                        </div>
                        </div></div>
                        {this.props.addingQuestion && <AddQuestionForm handleAddQuestionToggle={this.props.handleAddQuestionToggle} handleQuestionAdd={this.props.handleQuestionAdd}/>}
                        {!this.props.selectedQuestion ? (
                            <div>
                                {this.props.questions.sort((a, b) => {
                                    if(a.question[0].toLowerCase() === 'menu' || a.question[0].toLowerCase() === 'main menu'){
                                        return -1;
                                    }else{
                                        return 1;
                                    }
                                }).map((question) => {
                                    let showTopLevel = true;
                                    if(this.props.selectedQuestion){
                                        showTopLevel = false;
                                    }
                                    
                                    return ((showTopLevel && question.isTopLevel) || this.props.isSearched) && <QuestionItem filterTagOptions={this.props.filterTagOptions} showTags={this.props.showTags} selectedTagFilters={this.props.selectedTagFilters} handleTriggerTagsModal={this.props.handleTriggerTagsModal} handleTriggerDeleteModal={this.props.handleTriggerDeleteModal} initKb={this.props.initKb} handleMenuOptionSave={this.props.handleMenuOptionSave} onMenuOptionClick={this.props.onMenuOptionClick} key={question._id} question={question}/>
                                })}
                            </div>
                        ) : <QuestionItem filterTagOptions={this.props.filterTagOptions} showTags={this.props.showTags} selectedTagFilters={this.props.selectedTagFilters} handleTriggerTagsModal={this.props.handleTriggerTagsModal} handleTriggerDeleteModal={this.props.handleTriggerDeleteModal} initKb={this.props.initKb} handleMenuOptionSave={this.props.handleMenuOptionSave} onMenuOptionClick={this.props.onMenuOptionClick} question={this.props.selectedQuestion}/>}
                        
                    </div>
                ) : <Preloader/>}
                
            </div>
        );
    }
}