import React from 'react';
import PasswordResetForm from './PasswordResetForm';

class PasswordResetPage extends React.Component {
    render(){
        return (
            <div className="login_page" style={{ backgroundImage: 'linear-gradient( rgba(255,255,255,0.75), rgba(255,255,255,0.75) ), url(/images/login_bg.webp)' }}>
                <div className="login_page__container">
                    <div className="container">
                        <div className="col-6">
                            <img alt="Set Password" className="login_page__image" src="/images/password_page.svg"/>
                            <div className='login_page_container_text_overlay'>
                                <div className='login_page_container_text_overlay_sub'>QnA Builder</div>
                                <div className='login_page_container_text_overlay_title'>Reset Password</div>
                                <div className='login_page_container_text_overlay_text'>Please enter the email address linked to your account to receive a refund link</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <img alt="Verge AI Logo" className="login_page__logo" src="/images/logo_full.webp"/>
                            <PasswordResetForm/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PasswordResetPage;