let agent = JSON.parse(localStorage.getItem('user'));
let selectedCompany = localStorage.getItem('selectedCompany') || undefined;
let selectedChatbot = localStorage.getItem('selectedChatbot') ? JSON.parse(localStorage.getItem('selectedChatbot')) : undefined;
const initialState = agent ? { loggedIn: true, agent, selectedCompany, selectedChatbot } : {};

export default function authentication(state = initialState, action){
    switch(action.type){
        case 'AGENT_LOGIN_REQUEST':
            return {
                loggingIn: true,
                agent: action.agent
            }
        case 'AGENT_LOGIN_SUCCESS':
            return {
                loggedIn: true,
                agent: action.agent
            }
        case 'AGENT_PASSWORD_SET_REQUEST':
            return {
                settingPassword: true,
                ...state
            }
        case 'AGENT_PASSWORD_SET_SUCCESS':
            return {
                ...state,
                agent: {
                    ...state.agent,
                    agent: action.agent
                }
            }
        case 'SET_CURRENT_COMPANY':
            return {
                ...state,
                selectedCompany: action.companyId
            }
        case 'SET_CURRENT_CHATBOT':
            return {
                ...state,
                selectedChatbot: action.chatbot
            }
        case 'SET_CURRENT_AGENT_SET':
        case 'AVATAR_SET':
            return {
                ...state,
                agent: {
                    ...state.agent,
                    agent: action.agent
                }
            }
        case 'AGENT_PASSWORD_SET_FAILURE':
        case 'AGENT_LOGOUT':
        case 'AGENT_LOGIN_FAILURE':
            return { }
        default: 
            return state;
    }
}