import Modal from 'react-modal';
import { isVideo, isImage } from '../services/upload';

const ChatMediaViewer = (props) => {
    return (
        <Modal isOpen={true} className="media_modal" onRequestClose={() => { props.handleMediaClick(undefined) }} ariaHideApp={false}>
            {isImage(props.media.type) && <img src={props.media.url} alt="Preview"/>}
            {isVideo(props.media.type) && <video controls={true}  src={props.media.url} alt="Preview"/>}
        </Modal>
    );
};

export default ChatMediaViewer;