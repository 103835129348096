import AgentProfileAvatar from "./AgentProfileAvatar"
import StatusText from '../Base/StatusText'
import { HiOutlineMail } from 'react-icons/hi';

const AgentPopup = (props) => {
    const agent = props.agent;
    return (
        <div className="popup_box" onMouseEnter={() => { props.onMouseEnter(true) }} onMouseLeave={() => { props.onMouseLeave(false) }}>
            <AgentProfileAvatar agent={agent} size="100"/>
            <div className="agent_popup_details">
                <b className="agent_popup_details__name">{agent.first_name} {agent.last_name}</b>
                <StatusText status={agent.agent_settings.status}/>
                <div className="agent_popup_details__detail"><HiOutlineMail/> <a href={'mailto:' + agent.email}>{agent.email}</a></div>
            </div>
        </div>
    );
}; 

export default AgentPopup;