import Modal from 'react-modal';

const KbNotifyModal = (props) => {
    return (
        <Modal isOpen={props.confirmNotifyOpen} className="modal" onRequestClose={props.handleTriggerNotify} ariaHideApp={false}>
            <div className="modal__title">Notify Verge AI</div>
            <div className="modal__content">You are about to notify Verge AI about the changes you have made. This will alert Verge AI that your changes are done and your changes will be pushed to your bot.<br/><br/>Are you sure you are happy with all your changes?</div>
            <div className="modal__actions">
                <button className="button button_fit cancel" onClick={props.handleTriggerNotify}>Cancel</button>
                <button className="button button_fit" onClick={props.handleNotifyKb}>Notify Verge AI</button>
            </div>
        </Modal>
    );
}; 

export default KbNotifyModal;