import { titleCase } from '../../services/misc';
import { toTimestamp } from '../../services/dates';

const AuditTrailItem = (props) => {
    return (
        <div className={`audit_trail_item ${props.auditItem.type}`}>
            <div className={`audit_trail__badge ${props.auditItem.type}`}>{titleCase(props.auditItem.type)}</div>
            <div className="audit_trail__label">{props.auditItem.label}</div>
            <div className="timestamp">{toTimestamp(props.auditItem.createdAt)}</div>
        </div>
    );
}

export default AuditTrailItem;