import React from 'react';
import { connect } from 'react-redux';
import DashboardSelectedComponent from './DashboardSelectedComponent';
import Menu from './Menu/Menu';

class Dashboard extends React.Component{
    render(){
        return (
            <div className="dashboard">
                <Menu/>
                <div className="dashboard_content">
                    <DashboardSelectedComponent>{this.props.component}</DashboardSelectedComponent>
                </div>
            </div>
        ); 
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Dashboard);