import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Dashboard from '../components/Dashboard';

export const PrivateRoute = ({ dispatch, isAuthenticated, component: Component, loggedIn, auth, agent, ...rest }) => {
    let componentToRoute = undefined;
    const location = useLocation();

    if(!localStorage.getItem('user')){
        componentToRoute = <Redirect to="/login" />;
    }else{
        if(agent && !agent.agent_settings.password_set){
            if(location.pathname !== '/set-password'){
                componentToRoute = <Redirect to="/set-password" />;
            }
        }
    }

    return (
        <Route {...rest} component={(props) => (
            <div>
                {componentToRoute ? componentToRoute : <Dashboard component={<Component {...props}/>}/>}
            </div>
        )}/>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
    auth: state.auth ? state.auth : undefined,
    agent: state.auth.agent ? state.auth.agent.agent : undefined
})

export default connect(mapStateToProps)(PrivateRoute)