import { HiChevronDoubleRight, HiArrowLeft } from 'react-icons/hi';
const KbsBreadcrumbs = (props) => {
    return (
        <div className="kbs_breadcrumbs">
            {props.questionHistory.length > 0 && <div className="kbs_breadcrumbs__item"><div className="kbs_breadcrumbs__item__back" onClick={props.handleBreadcrumbBackClick}><HiArrowLeft/></div><div className="kbs_breadcrumbs__item__link" onClick={() => { props.handleBreadcrumbItemClick(0) }}>Back to top</div></div>}
            {props.questionHistory.map((questionItem) => {
                const active = (props.selectedQuestion && questionItem._id === props.selectedQuestion._id) && 'active';
                return <div key={questionItem._id} className={`kbs_breadcrumbs__item ${active}`}><div className="kbs_breadcrumbs__item_seperator"><HiChevronDoubleRight/></div><div className="kbs_breadcrumbs__item__link text-truncate" onClick={() => { props.handleBreadcrumbItemClick(questionItem._id) }}>{questionItem.question[0]}</div></div>
            })}
        </div>
    );
};

export default KbsBreadcrumbs;