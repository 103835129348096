
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/Login/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Kbs from '../components/Kbs/Kbs';
import SetPasswordPage from '../components/Password Set/SetPasswordPage';
import { history } from '../helpers/history';
import AgentPage from '../components/Agents/AgentPage';
import KbPage from '../components/Kbs/KbPage';
import PasswordResetPage from '../components/PasswordReset/PasswordResetPage';
import ResetPassword from '../components/PasswordReset/ResetPassword';

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <PrivateRoute path="/" exact={true} component={Kbs}/>
                <PrivateRoute path="/profile" component={AgentPage}/>
                <Route path="/set-password" component={SetPasswordPage}/>
                <PrivateRoute path="/knowledge-set/:id" component={KbPage}/>
                <PrivateRoute path="/knowledge-set" component={Kbs}/>
                <PublicRoute path="/login" exact={true} component={LoginPage}/>
                <PublicRoute path="/reset-password/:id" component={ResetPassword}/>
                <PublicRoute path="/reset-password" component={PasswordResetPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </div>
    </Router>
);

export default AppRouter;