import { connect } from 'react-redux';
import React from 'react';
import { alertActions } from '../actions/alerts';
import { history } from '../helpers/history';
import '../styles/styles.scss';
import AppRouter from '../routers/AppRouter';

class App extends React.Component {
  constructor(props){
    super(props);

    const { dispatch } = this.props;
    history.listen(() => {
      // Clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render(){
    return (
      <div className="App">
        <AppRouter/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alerts } = state;
  return {
    alert: alerts
  }
}

export default connect(mapStateToProps)(App);
