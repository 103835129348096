import React from 'react';
import { connect } from 'react-redux';
import { knowledgeSetService } from '../../services/knowledgeSets';
import Preloader from '../Preloader'; 
import AuditTrailItem from './AuditTrailItem';
import { HiOutlineX } from 'react-icons/hi';
import LoadMore from '../Base/LoadMore';

import socket from '../../services/socket';

class AuditTrailWidget extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            auditTrail: [],
            loadingAuditTrail: true,
            auditTrailLimit: 20,
            auditTrailOffset: 20,
            loadingMore: false,
            hasMore: false
        };

        this.handleLoadMore = this.handleLoadMore.bind(this);
    }
    async componentDidMount(){
        await this.initAuditTrail();
        socket.on('auditTrailUpdate', ({ id }) => {
            if(this.props.kb === id){
                this.initAuditTrail()
            }
        });
    }

    componentWillUnmount(){
        socket.off('auditTrailUpdate');
    }

    async initAuditTrail(){
        const auditTrail = await knowledgeSetService.getAuditTrail(this.props.kb, this.props.auth.agent.token, this.state.auditTrailLimit + 1);
        
        this.setState({ hasMore: auditTrail.length > this.state.auditTrailLimit ? true : false });

        if(auditTrail.length > this.state.auditTrailLimit){
            auditTrail.pop();
        }
        
        this.setState({
            auditTrail,
            loadingAuditTrail: false
        });
    }

    handleLoadMore(){
        this.setState(prevState => ({
            auditTrailLimit: prevState.auditTrailLimit + prevState.auditTrailOffset,
            loadingMore: true
        }), () => {
            this.initAuditTrail().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    render(){
        return (
            <div className="audit_trail_widget">
                <div className="kb_chat__title">Audit Trail <div className="close_chat" onClick={this.props.handleAuditTrailToggle}><HiOutlineX/></div></div>
                    <div className="audit_trail_widget__innner">
                        {!this.state.loadingAuditTrail ? this.state.auditTrail.length > 0 ? (<div>{this.state.auditTrail.map(auditItem => <AuditTrailItem key={auditItem._id} auditItem={auditItem}/>)}</div>) : <div className="no_items">No changes have been made</div> : <Preloader/>}  
                        {this.state.hasMore && <LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/>}
                    </div>
                </div>
        );
    }   
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AuditTrailWidget);