import { titleCase } from '../../services/misc';
import { HiBadgeCheck, HiMinusCircle, HiOutlineXCircle } from 'react-icons/hi';

const StatusText = (props) => {
    const status = titleCase(props.status);
    let icon;
    switch(status){
        case 'Available':
            icon = <HiBadgeCheck className="status_icon"/>
            break;
        case 'Busy':
            icon = <HiMinusCircle className="status_icon" />
            break;
        case 'Offline':
        default:
            icon = <HiOutlineXCircle className="status_icon" />
            break;
    }

    return <div className={'status_display ' + status}>{icon}<span>{status}</span></div>;
};

export default StatusText;