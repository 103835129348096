import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import AgentProfileAvatar from './AgentProfileAvatar';
import ChangeAvatar from './ChangeAvatar';
import { agentService } from '../../services/agents';
import { agentActions } from '../../actions/agents';
import Badge from '../Base/Badge';
import Status from '../Base/Status';
import EditAgentForm from './EditAgentForm';

class AgentPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agent: this.props.auth.agent.agent,
            changingAvatar: false,
            editSubmiting: false
        };

        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleImageChanged = this.handleImageChanged.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
    }

    handleImageSelect(inputElement){
        inputElement.click();
    }

    handleEditSubmit(e, agent){
        e.preventDefault();
        this.setState({ editSubmiting: true });
        agentService.updateAgent(this.props.auth.agent.token, agent).then((agentRecieved) => {
            this.props.dispatch(agentActions.setCurrentAgent(agentRecieved));
            this.setState({ editSubmiting: false });
        });

    }

    handleImageChanged(e){
        this.setState({changingAvatar: true})
        agentActions.setAvatar(this.props.auth.agent.token, this.state.agent._id, e.target.files[0]).then((agent) => {
            this.props.dispatch({
                type: 'AVATAR_SET',
                agent
            } );
            
            this.setState({ changingAvatar: false });
        });
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-6 agent_profile_card">
                            <AgentProfileAvatar size={150} changingAvatar={this.state.changingAvatar} agent={this.state.agent}/>
                            <ChangeAvatar handleImageSelect={this.handleImageSelect} handleImageChanged={this.handleImageChanged}/>
                        </div>
                        <div className="col-6">
                            <div className="agent_info">
                                <div className="agent_name">{this.state.agent.first_name} {this.state.agent.last_name}</div>
                                <Status agent={this.state.agent}/>
                                <Badge text={this.state.agent.role}/>
                            </div>
                        </div>
                    </div>
                </PageContainer>
                <PageContainer>
                    <h2>Edit Information</h2>
                    <EditAgentForm submiting={this.state.editSubmiting} onSubmit={this.handleEditSubmit} agent={this.state.agent}/>
                </PageContainer>
                <PageContainer>
                    <h2>Reset Password</h2>
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentPage);