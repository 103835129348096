import { FiUploadCloud, FiSave, FiMail } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { HiArrowLeft, HiBeaker, HiOutlineDownload } from 'react-icons/hi';
import QuestionSearch from './../Questions/QuestionSearch';

const KbTopBar = (props) => {
    const kb = props.kb;
    return (
        <div className="kb_page_top_bar">
            <div className="kb_page_top_bar__info">
                <NavLink to="/knowledge-set" className="back"><HiArrowLeft/></NavLink>
                <div className="kb_page_top_bar__title">{kb.name}<div className="audit_trail_link" onClick={props.handleAuditTrailToggle}>{!props.auditTrailOpen ? 'View audit trail' : 'Close audit trail'}</div></div>
                <QuestionSearch clearSearch={props.clearSearch} loadingQuestions={props.loadingQuestions} isSearching={props.isSearching} termSearched={props.termSearched} questions={props.questions} handleQuestionSearchSubmit={props.handleQuestionSearchSubmit} isSearched={props.isSearched} handleQuestionSearchChange={props.handleQuestionSearchChange} searchTerm={props.searchTerm}/>
            </div>
            <div className="kb_page_top_bar__actions">
                {props.kb.custom_question_answering && <div className="kb_page_top_bar__action" onClick={props.handleExport}><HiOutlineDownload/>Export</div>}
                {props.kb.custom_question_answering && <div className="kb_page_top_bar__action" onClick={props.handleChatToggle}><HiBeaker/>Test Bot</div>}
                {props.kb.custom_question_answering && <div className="kb_page_top_bar__action" onClick={props.handlePushChanges}><FiSave/>Save to Testing Enviroment</div>}
                {props.kb.custom_question_answering && props.kb.allow_publish && <div className="kb_page_top_bar__action main" onClick={props.handleTriggerPublishModal}><FiUploadCloud/>Publish Changes</div>}
                {['super_user', 'admin'].includes(props.currentAgent.role) && props.kb.custom_question_answering && !props.kb.allow_publish && <div className="kb_page_top_bar__action main" onClick={props.handleTriggerNotifyhModal}><FiMail/>Notifiy Verge AI</div>}

            </div>
        </div>
    );
}

export default KbTopBar;