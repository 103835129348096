import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader'; 
import { knowledgeSetService } from '../../services/knowledgeSets';
import QuestionList from '../Questions/QuestionList';
import KbTopBar from './KbTopBar';
import KbChat from './KbChat';
import KbPublishModal from './KbPublishModal';
import KbNotifyModal from './KbNotifyModal';
import KbPreloaderModal from './KbPreloaderModal';
import KbAlertModal from './KbAlertModal';
import KbDeleteModal from './KbDeleteModal';
import AuditTrailWidget from './AuditTrailWidget'

import socket from '../../services/socket';
import KbTagModal from './KbTagModal';

class KbPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingPage: true,
            loadingQuestions: true,
            kb: undefined,
            questions: [],
            selectedQuestion: undefined,
            addingQuestion: false,
            searchTerm: '',
            isSearching: false,
            isSearched: false,
            termSearched: undefined,
            chatOpened: false,
            auditTrailOpen: false,
            confirmPublishOpen: false,
            confirmNotifyOpen: false,
            confirmDeleteOpen: false,
            questionToBeDeleted: undefined,
            actionInProgess: false,
            actionText: '',
            acitonDescription: '',
            alertShown: false,
            alertText: '',
            alertDescription: '',
            questionHistory: [],
            confirmTagOpen: false,
            questionToBeTagged: undefined,
            savingTags: false,
            filterTagOptions: [],
            selectedTagFilters: [],
            showTags: false,
            savingKb: false
        };

        this.initKb = this.initKb.bind(this);
        this.handleMenuOptionClick= this.handleMenuOptionClick.bind(this);
        this.handleQuestionAdd = this.handleQuestionAdd.bind(this);
        this.handleAddQuestionToggle = this.handleAddQuestionToggle.bind(this);
        this.handleMenuOptionSave = this.handleMenuOptionSave.bind(this);
        this.handleQuestionSearchChange = this.handleQuestionSearchChange.bind(this);
        this.handleQuestionSearchSubmit = this.handleQuestionSearchSubmit.bind(this);
        this.initQuestionsSearch = this.initQuestionsSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleChatToggle = this.handleChatToggle.bind(this);
        this.handleAuditTrailToggle = this.handleAuditTrailToggle.bind(this);
        this.handleTriggerPublishModal = this.handleTriggerPublishModal.bind(this);
        this.handlePushChanges = this.handlePushChanges.bind(this);
        this.handlePublishChanges = this.handlePublishChanges.bind(this);
        this.handleTriggerAlert = this.handleTriggerAlert.bind(this);
        this.handleTriggerDeleteModal = this.handleTriggerDeleteModal.bind(this);
        this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
        this.handleTriggerNotifyhModal = this.handleTriggerNotifyhModal.bind(this);
        this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
        this.goToBreadcrumbsItem = this.goToBreadcrumbsItem.bind(this);
        this.handleNotifyKb = this.handleNotifyKb.bind(this);
        this.handleBreadcrumbBackClick = this.handleBreadcrumbBackClick.bind(this);
        this.handleTriggerTagsModal = this.handleTriggerTagsModal.bind(this);
        this.handleTagsSave = this.handleTagsSave.bind(this);
        this.handleTagFilterChange = this.handleTagFilterChange.bind(this);
        this.handleToggleShowTags = this.handleToggleShowTags.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount(){
        this.initKb();  
        socket.on('questionUpdate', ({ id }) => {
            if(this.props.match.params.id === id && !this.state.savingKb){
                this.initKb()
            }
        });  

        knowledgeSetService.getKbTags(this.props.match.params.id, this.props.auth.agent.token).then(tags => {
            this.setState({
                filterTagOptions: tags.map(tag => ({ value: tag, label: tag.label }))
            })
        })
    }

    componentWillUnmount(){
        socket.off('questionUpdate');
    }

    handleTagFilterChange(e){
        this.setState({ selectedTagFilters: e });
    }

    async initKb(){
        this.setState({ savingKb: true })
        const kbId = this.props.match.params.id;
        const kb = await knowledgeSetService.getKnowledgeSet(kbId, this.props.auth.agent.token);
        let questions = [];
        if(this.state.searchTerm && this.state.searchTerm.length > 0){
            questions = await this.initQuestionsSearch(false);
        }else{
            questions = await knowledgeSetService.getKnowledgeSetQuestions(kbId, this.props.auth.agent.token)
        }
        this.setState({
            kb,
            questions,
            loadingPage: false,
            loadingQuestions: false
        });

        this.refreshSelectedQuestion();
        this.setState({ savingKb: false })
    }

    handleMenuOptionClick(id){
        this.setState({ loadingQuestions: true })
        knowledgeSetService.getQuestion(id, this.props.auth.agent.token).then((question) => {
            this.setState((prevState) => ({ 
                selectedQuestion: question,
                loadingQuestions: false,
                questionHistory: [...prevState.questionHistory, question]
            }))
        });
    }

    handleAddQuestionToggle(enabled){
        this.setState({
            addingQuestion: enabled
        });
    }

    handleTriggerPublishModal(){
        this.setState(prevState => ({
            confirmPublishOpen: !prevState.confirmPublishOpen
        }));
    }

    handleTriggerNotifyhModal(){
        this.setState(prevState => ({
            confirmNotifyOpen: !prevState.confirmNotifyOpen
        }));
    }

    handleTriggerDeleteModal(questionId){
        this.setState(prevState => ({
            confirmDeleteOpen: !prevState.confirmDeleteOpen,
            questionToBeDeleted: questionId
        }));
    }

    handleTriggerAlert(){
        this.setState(prevState => ({
            alertShown: !prevState.alertShown,
            alertText: '',
            alertDescription: ''
        }));
    }

    async handleMenuOptionSave(questionId, question){
        this.setState({ savingKb: true })
        const questionToAdd = {
            knowledgeSet: this.state.kb._id,
            question: [ question ],
            answer: 'Enter your answer',
            menuOptions: [],
            owner: this.props.auth.agent.agent._id
        };

        const questionAdded = await knowledgeSetService.addQuestion(questionToAdd, this.props.auth.agent.token);
        const questionToUpdate = await knowledgeSetService.getQuestion(questionId, this.props.auth.agent.token)

        if(questionToUpdate){
            let newMenuOptions = questionToUpdate.menuOptions.map((questionItem) => ({ displayOrder: questionItem.displayOrder, question: questionItem.question._id }));
            newMenuOptions = [...newMenuOptions, {
                displayOrder: questionToUpdate.menuOptions.length + 1,
                question: questionAdded._id
            }];

            await knowledgeSetService.updateQuestion(questionToUpdate._id, { menuOptions: newMenuOptions }, this.props.auth.agent.token);
            await this.initKb();
        }
    }

    refreshSelectedQuestion(){
        if(this.state.selectedQuestion){
            const updatedQuestion = this.state.questions.find(question => question._id === this.state.selectedQuestion._id);
            if(updatedQuestion){ this.setState({ selectedQuestion: updatedQuestion }) }
        }
    }

    handleQuestionAdd(question, answer){
        const questionToAdd = {
            knowledgeSet: this.state.kb._id,
            question: [ question ],
            answer,
            menuOptions: [],
            isTopLevel: true,
            owner: this.props.auth.agent.agent._id
        };

        knowledgeSetService.addQuestion(questionToAdd, this.props.auth.agent.token).then((questionAdded) => {
            const newQuestions = [questionAdded, ...this.state.questions];
            this.setState((prevState) => {
                if(prevState.questions !== newQuestions){
                    return ({
                        addingQuestion: false,
                        questions: newQuestions
                    });
                }
            });
        });

        
    }

    handleQuestionSearchChange(e){
        this.setState({
            searchTerm: e.target.value
        });
    }

    clearSearch(){
        this.initKb();
        this.setState({
            isSearched: false,
            termSearched: undefined,
            isSearching: false,
            searchTerm: '',
            questionHistory: [],
            selectedQuestion: undefined,
            loadingQuestions: true
        });
    }

    handleTriggerTagsModal(questionId){
        if(questionId){
            this.setState({
                confirmTagOpen: true,
                questionToBeTagged: questionId
            });
        }else{
            this.setState({
                confirmTagOpen: false,
                questionToBeTagged: undefined
            });
        }
    }

    async handleTagsSave(question){
        this.setState({ savingTags: true, confirmTagOpen: false, questionToBeTagged: undefined });
        question.tags = question.tags && question.tags.length > 0 ? question.tags.map(questionTag => questionTag._id ) : [];
        await knowledgeSetService.updateQuestion(question._id, { tags: question.tags }, this.props.auth.agent.token);
        await this.initKb();
        this.setState({ savingTags: false });
    }

    async initQuestionsSearch(showPreloader = false){
        if(showPreloader){
            this.setState({ loadingQuestions: true })
        }
        const questions = await knowledgeSetService.searchQuestions(this.state.kb._id, this.state.searchTerm, this.props.auth.agent.token);
        this.setState((prevState) => {
            return {
                questions,
                termSearched: prevState.searchTerm,
                isSearching: false,
                loadingQuestions: false
            };
        });

        return questions;
    }

    handlePushChanges(){
        this.setState({ 
            actionInProgess: true, 
            actionText: 'Saving Changes', 
            acitonDescription: 'Please be patient wait while your changes are pushed to the testing enviroment. This could take a minute.' 
        });

        knowledgeSetService.saveToQna(this.state.kb._id, this.props.auth.agent.token).then(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Changes Saved',
                alertDescription: 'Your changes have been saved and pushed to the test enviroment. Click "Test Changes" to test your questions.'
            });
        }).catch(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Save Failed',
                alertDescription: 'Your changes failed to save and push. Please try again in a few minutes.'
            })
        });
    }

    async handlePublishChanges(){
        this.setState({ 
            confirmPublishOpen: false,
            actionInProgess: true, 
            actionText: 'Publishing Changes', 
            acitonDescription: 'Please be patient wait while your changes are published. This could take a minute.' 
        });

        await knowledgeSetService.saveToQna(this.state.kb._id, this.props.auth.agent.token);
        knowledgeSetService.publishToQna(this.state.kb._id, this.props.auth.agent.token).then(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Changes Published',
                alertDescription: 'Your changes have been published.'
            });
        }).catch(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Publish Failed',
                alertDescription: 'Your changes failed to publish. Please try again in a few minutes.'
            })
        });
    }

    handleChatToggle(){
        this.setState((prevState) => ({
            chatOpened: !prevState.chatOpened,
            auditTrailOpen: false
        }));
    }

    handleAuditTrailToggle(){
        this.setState((prevState) => ({
            auditTrailOpen: !prevState.auditTrailOpen,
            chatOpened: false
        }));
    }

    handleQuestionSearchSubmit(e){
        e.preventDefault();
        if(this.state.searchTerm.length > 0){
            this.setState({
                selectedQuestion: undefined,
                questionHistory: [],
                isSearched: true,
                isSearching: true
            });
            this.initQuestionsSearch(true)
        }else{
            this.clearSearch();
        }
    }

    handleDeleteQuestion(){
        this.setState({ 
            savingKb: true,
            actionInProgess: true, 
            actionText: 'Deleting Question', 
            acitonDescription: 'Please be patient while your question is removed from the knowledge set.',
            confirmDeleteOpen: false
        });
        
        knowledgeSetService.deleteQuestion(this.state.questionToBeDeleted, this.props.auth.agent.token).then((question) => {
            this.initKb().then(() => {
                this.setState({ 
                    actionInProgess: false, 
                    actionText: '', 
                    acitonDescription: '',
                    questionToBeDeleted: undefined,
                    savingKb: false,
                }, () => {
                    this.handleBreadcrumbBackClick();
                })
            });
        }).catch(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Delete Failed',
                alertDescription: 'Your question could not be deleted. Please try again in a few minutes.',
                savingKb: false
            })
        });
    }

    handleNotifyKb(){
        this.setState({ 
            confirmNotifyOpen: false,
            actionInProgess: true, 
            actionText: 'Notifying Verge AI', 
            acitonDescription: 'Please be patient wait while Verge AI is alerted of your changes.' 
        });
        knowledgeSetService.saveToQna(this.state.kb._id, this.props.auth.agent.token).then(() => {            
            knowledgeSetService.notifyKb(this.state.kb._id, this.props.auth.agent.token).then(() => {
                this.setState({ 
                    actionInProgess: false, 
                    actionText: '', 
                    acitonDescription: '' ,
                    alertShown: true,
                    alertText: 'Verge AI Notified',
                    alertDescription: 'Your changes will be pushed to your live bot by our dev team.'
                });
            }).catch(e => {
                this.setState({ 
                    actionInProgess: false, 
                    actionText: '', 
                    acitonDescription: '' ,
                    alertShown: true,
                    alertText: 'Notify Failed',
                    alertDescription: 'Your changes failed to save and push. Please try again in a few minutes.'
                })
            });
            
        }).catch(() => {
            this.setState({ 
                actionInProgess: false, 
                actionText: '', 
                acitonDescription: '' ,
                alertShown: true,
                alertText: 'Save Failed',
                alertDescription: 'Your changes failed to save and push. Please try again in a few minutes.'
            })
        });
    }

    async handleExport(){
        this.setState({ exporting: true });
        const response = await knowledgeSetService.exportKbs(this.props.auth.agent.token, this.state.kb._id);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Questions.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.setState({ exporting: false });
    }

    async handleBreadcrumbItemClick(questionId){
        await this.goToBreadcrumbsItem(questionId);
    }

    async handleBreadcrumbBackClick(){
        if(this.state.questionHistory.length === 0){ return; }
        if(this.state.questionHistory.length === 1){
            return await this.goToBreadcrumbsItem(0);
        }

        const currentIndex = this.state.questionHistory.findIndex(historyItem => historyItem._id === this.state.selectedQuestion._id);
        const newSelectedQuestion = this.state.questionHistory[currentIndex - 1];      
        await this.goToBreadcrumbsItem(newSelectedQuestion._id); 
    }

    async goToBreadcrumbsItem(questionId){
        this.setState({ loadingQuestions: true });
        if(questionId === 0){
            
            this.setState({ selectedQuestion: undefined, questionHistory: [] }); 
            return await this.initKb(); 
        }

        try{
            const historyIndex = this.state.questionHistory.findIndex(questionItem => questionItem._id === questionId);
            let newQuestionHistory = [];
            for (let index = 0; index <= historyIndex; index++) {
                newQuestionHistory.push(this.state.questionHistory[index]);            
            }

            this.setState((prevState) => ({
                selectedQuestion: prevState.questionHistory[historyIndex],
                questionHistory: newQuestionHistory
            }));
            
            await this.initKb();  
        }catch(e){
            this.setState({ loadingQuestions: false });
        }
    }

    handleToggleShowTags(showTags){
        this.setState({ showTags })
    }

    render(){
        return (
            <div className="kb_page_container">
                {!this.state.loadingPage ? 
                    <div className="kb_page">
                        <KbTopBar currentAgent={this.props.auth.agent.agent} handleAuditTrailToggle={this.handleAuditTrailToggle} auditTrailOpen={this.state.auditTrailOpen} handlePushChanges={this.handlePushChanges} handleExport={this.handleExport} handleTriggerNotifyhModal={this.handleTriggerNotifyhModal} handleTriggerPublishModal={this.handleTriggerPublishModal} handleChatToggle={this.handleChatToggle} kb={this.state.kb} clearSearch={this.clearSearch} isSearching={this.state.isSearching} termSearched={this.state.termSearched} handleQuestionSearchSubmit={this.handleQuestionSearchSubmit} isSearched={this.state.isSearched} handleQuestionSearchChange={this.handleQuestionSearchChange} searchTerm={this.state.searchTerm} selectedQuestion={this.state.selectedQuestion} handleAddQuestionToggle={this.handleAddQuestionToggle} questions={this.state.questions} loadingQuestions={this.state.loadingQuestions}/>
                        <div className="kb_page__panel">
                            <QuestionList className={this.state.auditTrailOpen || this.state.chatOpened ? 'widget_expanded' : ''} filterTagOptions={this.state.filterTagOptions} handleToggleShowTags={this.handleToggleShowTags} showTags={this.state.showTags} selectedTagFilters={this.state.selectedTagFilters} handleTagFilterChange={this.handleTagFilterChange} loadingQuestions={this.state.loadingQuestions} handleTriggerTagsModal={this.handleTriggerTagsModal} handleBreadcrumbBackClick={this.handleBreadcrumbBackClick} handleBreadcrumbItemClick={this.handleBreadcrumbItemClick} questionHistory={this.state.questionHistory} isSearched={this.state.isSearched} initKb={this.initKb} handleTriggerDeleteModal={this.handleTriggerDeleteModal} handleMenuOptionSave={this.handleMenuOptionSave} handleQuestionAdd={this.handleQuestionAdd} handleAddQuestionToggle={this.handleAddQuestionToggle} addingQuestion={this.state.addingQuestion} selectedQuestion={this.state.selectedQuestion} onMenuOptionClick={this.handleMenuOptionClick} questions={this.state.questions}/>
                            {this.state.chatOpened && <KbChat kb={this.state.kb._id} handleChatToggle={this.handleChatToggle} selectedTagFilters={this.state.selectedTagFilters}/>}
                            {this.state.auditTrailOpen && <AuditTrailWidget kb={this.state.kb._id} handleAuditTrailToggle={this.handleAuditTrailToggle}/>}
                        </div>
                        <KbPublishModal handlePublishChanges={this.handlePublishChanges} handleTriggerPublishModal={this.handleTriggerPublishModal} confirmPublishOpen={this.state.confirmPublishOpen}/>
                        <KbDeleteModal handleDeleteQuestion={this.handleDeleteQuestion} confirmDeleteOpen={this.state.confirmDeleteOpen} handleTriggerDeleteModal={this.handleTriggerDeleteModal} />
                        <KbPreloaderModal shown={this.state.actionInProgess} title={this.state.actionText} description={this.state.acitonDescription}/>
                        <KbNotifyModal handleNotifyKb={this.handleNotifyKb} handleTriggerNotify={this.handleTriggerNotifyhModal} confirmNotifyOpen={this.state.confirmNotifyOpen}/>
                        <KbAlertModal shown={this.state.alertShown} title={this.state.alertText} description={this.state.alertDescription} handleTriggerAlert={this.handleTriggerAlert}/>   
                        {this.state.confirmTagOpen && <KbTagModal questionId={this.state.questionToBeTagged} handleTagsSave={this.handleTagsSave} isOpen={this.state.confirmTagOpen} onRequestClose={() => { this.handleTriggerTagsModal(undefined) }}/>}
                        <KbPreloaderModal shown={this.state.savingTags} title="Saving tags" description={"Please be patient while question tags are updated"}/>
                    </div> : 
                <Preloader/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(KbPage);