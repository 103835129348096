import { IoSend } from 'react-icons/io5';

const KbChatMessageBar = (props) => {
    return (
        <form className="kb_chat__message_bar" onSubmit={props.handleMessageSend}>
            {props.messages.length === 0 && <div className="kb_chat__start" onClick={props.handleChatState}>Start</div>}
            <input onChange={props.handleMessageChange} value={props.messageValue} placeholder="Enter your message"/>
            <button disabled={!(props.messageValue.length > 0)} className="button"><IoSend/></button>
        </form>
    );
};  

export default KbChatMessageBar;