import TextareaAutosize from 'react-textarea-autosize';
import { HiPlusCircle, HiTrash } from 'react-icons/hi';
import React from 'react';

export default class AddQuestionForm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            questionValue: '',
            answerValue: ''
        };

        this.handleTextareaChange = this.handleTextareaChange.bind(this);
    }

    handleTextareaChange(e){
        const fieldName = e.target.name;
        this.setState({
            [fieldName]: e.target.value
        });
    }

    render(){
        return (
            <div className="question_item">
                    <div className="question_iten__left question_item__questions">
                        <TextareaAutosize name="questionValue" onChange={this.handleTextareaChange} value={this.state.questionValue} placeholder="Enter your question"/>
                    </div>
                    <div className="question_iten__right">
                    <TextareaAutosize name="answerValue" onChange={this.handleTextareaChange} value={this.state.answerValue} placeholder="Enter your answer"/>
                    <div className="question_actions">
                        <button className="button button_fit cancel" onClick={() => { this.props.handleAddQuestionToggle(false) }}>Discard <HiTrash/></button>
                        <button className="button button_fit" disabled={!(this.state.questionValue.length > 0 && this.state.answerValue.length > 0)} onClick={() => { this.props.handleQuestionAdd(this.state.questionValue, this.state.answerValue) }}>Add <HiPlusCircle/></button>
                    </div>
                    </div>
                </div>
        );
    }
};