import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Preloader from '../Preloader';
import { knowledgeSetService } from '../../services/knowledgeSets';
import Select from 'react-select';

class KbTagModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            question: undefined,
            tagOptions: [],
            loadingQuestion: true
        };

        this.handleTagSelect = this.handleTagSelect.bind(this);
    }

    async componentDidMount(){
        const question = await knowledgeSetService.getQuestion(this.props.questionId, this.props.auth.agent.token);
        console.log(question);
        const kbTags = await knowledgeSetService.getKbTags(question.knowledgeSet, this.props.auth.agent.token);
        const kbTagOptions = kbTags && kbTags.length > 0 ? kbTags.map(kbTag => ({ value: kbTag, label: kbTag.label })) : [];
        this.setState({ loadingQuestion: false, question, tagOptions: kbTagOptions });
    }

    handleTagSelect(e){
        this.setState(prevState => ({
            question: {
                ...prevState.question,
                tags: e.map(selectOption => selectOption.value)
            }
        }))
    }

    render(){
        return (
            <Modal isOpen={this.props.isOpen} className="modal" onRequestClose={this.props.onRequestClose} ariaHideApp={false}>
                <div className="modal__title">Tags</div>
                {!this.state.loadingQuestion && this.state.question ? (
                    <div>
                        {this.state.question.tags.length < this.state.tagOptions.length && <button className="select_all button button_fit" onClick={() => { this.setState(prevState => ({ question: { ...prevState.question, tags: prevState.tagOptions.map(tagOption => tagOption.value) } })) }}>Select all</button>}
                        <Select className="select" closeMenuOnSelect={false} tabSelectsValue={true} isMulti onChange={this.handleTagSelect} value={this.state.question.tags ? this.state.tagOptions.filter(tagOption => this.state.question.tags && this.state.question.tags.find(questionTag => questionTag._id.toString() === tagOption.value._id.toString())) : []} options={this.state.tagOptions}/>
                    </div>
                    ) : <div><Preloader/></div>}
                <div className="modal__actions">
                    <button className="button button_fit cancel" onClick={this.props.onRequestClose}>Cancel</button>
                    <button className="button button_fit" disabled={this.state.loadingQuestion} onClick={() => { this.props.handleTagsSave(this.state.question) }}>Save</button>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(KbTagModal);